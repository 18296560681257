<template>
  <div class="standard-page">
    <!-- Show the button to go back -->
    <v-btn
      text
      class="mb-4"
      color="primary"
      @click="$router.go(-1)"
    >
      <v-icon left>
        arrow_back
      </v-icon>

      Back
    </v-btn>

    <v-card flat class="pt-8 px-md-6">
      <v-card-text class="mb-4">
        <v-row>
          <v-col cols="12" md="6">
            <!-- Show the input here -->
            <v-text-field
              v-model="formData.name"
              :error-messages="$v.formData.name.$anyError ? ['Please enter a valid name'] : null"
              @blur="$v.formData.name.$touch"
              placeholder="Delhi Fashion Influencers"
              label="Group Name"
              outlined
            />
          </v-col>

          <v-col
            cols="12"
            md="6"
            lg="4"
          >
            <!-- Show the platforms selector -->
            <div style="margin-top: -16px">
              <div class="mb-2">
                Social Networks
              </div>
              <div class="d-flex items-center">
                <v-img
                  v-for="platform in Object.keys(formData.platforms)"
                  :key="platform"
                  :class="{ 'opaque': !formData.platforms[platform] }"
                  @click="() => (formData.platforms[platform] = !formData.platforms[platform])"
                  :src="`/img/socials/${platform}.svg`"
                  class="mr-3 pointer"
                  max-width="40"
                  height="40"
                ></v-img>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="pl-4 pb-8">
        <!-- <v-tooltip
          v-if="!model"
          bottom
        >
          <template v-slot:activator="{ on }">
            <v-chip
              label
              color="primary"
              v-on="on"
            >
              <v-icon
                left
                small
              >
                account_balance
              </v-icon>

              {{ nFormatter(availableModuleUsage) }} Groups
            </v-chip>
          </template>

          <span>
            You can make {{ availableModuleUsage }} more groups
          </span>
        </v-tooltip>

        @todo: implement "availableModuleUsage <= 0" condition below if enabled
        -->

        <v-spacer />

        <v-btn
          depressed
          color="primary"
          @click="validate"
          :disabled="isLoading || isValidating"
          :loading="isLoading || isValidating"
        >
          {{ model ? "Update Group" : "Create Group" }}

          <v-icon right>
            arrow_forward
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
// Import helper functions
import { required, minLength, maxLength } from "vuelidate/lib/validators"

// Export the SFC
export default {
  // Name of the component
  name: "InfluencerGroupsForm",

  // Accept incoming data from parent
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    },

    model: {
      type: Object,
      required: false,
      default: null
    }
  },

  // Define local data variables
  data: () => ({
    // Whether or not is validating the form
    isValidating: false,

    // The form data to be used
    formData: {
      name: "",
      platforms: {
        instagram: true,
        tiktok: false,
        youtube: false,
        // snapchat: false,
        // linkedin: false,
      },
    },
  }),

  // Define computable properties
  computed: {
    /**
     * Compute the available module usage
     *
     * @returns {Number}
     */
    availableModuleUsage() {
      return this.$store.getters["auth/availableModuleUsage"]("influencer-group")
    },
  },

  // Define validations for this form
  validations() {
    // Define the object
    const object = {
      formData: {
        name: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(200)
        },
      }
    }

    // Return the object
    return object
  },

  // Define method functions
  methods: {
    /**
     * Run the validations and if correct
     * If valid, emit to the parent
     *
     * @returns {void}
     */
    async validate() {
      // Start isValidating
      this.isValidating = true

      // Check if the inputs are valid
      await this.$v.formData.$touch()

      // If it is invalid
      if (this.$v.formData.$anyError) {
        // Hide the validating
        this.isValidating = false

        return
      }

      // If none of the platform is selected
      if (Object.values(this.formData.platforms).filter(v => v).length === 0) {
        // Show a toast message
        this.$store.dispatch("toasts/add", { text: "Please select a social network for this group" })

        // Hide the validating
        this.isValidating = false

        // Stop further execution
        return
      }

      // Clone the formData object
      const formData = JSON.parse(JSON.stringify(this.formData))

      // Otherwise, emit the event
      this.$emit("submit", formData)

      // Hide the validating
      this.isValidating = false
    },
  },

  /**
   * As soon as the component data has been created
   *
   * @returns {void}
   */
  created() {
    // If we have a model data already
    if (this.model) {
      // Update the formData with these values
      this.formData = {
        name: this.model.name,

        platforms: {
          instagram: this.model.platforms.includes("instagram"),
          youtube: this.model.platforms.includes("youtube"),
          tiktok: this.model.platforms.includes("tiktok"),

          // snapchat: this.model.platforms.includes("snapchat"),
          // linkedin: this.model.platforms.includes("linkedin"),
        },
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.opaque
  opacity 0.2

.contain-select-width
  min-width unset !important
  width unset !important
</style>
